import { Navigate, Outlet } from "react-router-dom";

// import useAuth from '@hooks/useAuth';
import PATHS from "@routes/paths";
import LoadingScreen from "@components/LoadingScreen";
import useUser from "@hooks/useUser";

// ----------------------------------------------------------------------
export default function GuestGuard() {
  const { isAuthenticated, isInitialized } = useUser();

  if (isAuthenticated) {
    return <Navigate to={PATHS.root} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <Outlet />;
}
