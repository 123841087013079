import { Box, Typography } from "@mui/material";

import { CustomButton } from "@components/ui/Button";
import { Google } from "@mui/icons-material";
import background from "@/assets/auth-bg.webp";
import logo from "@/assets/logo-blue.png";
import useUser from "@hooks/useUser";
import LoadingScreen from "@components/LoadingScreen";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import toast from "react-hot-toast";

const Login = () => {
  const { isInitialized, isAuthenticated } = useUser();

  const [searchParams] = useSearchParams(window.location.search);

  useEffect(() => {
    if (searchParams.get("error_code") === "2586742") {
      toast.error("You need to allow all permissions to use SmartReplies.ai",{
        id: "error",
      });
    }
  }, [searchParams]);

  return !isInitialized || isAuthenticated ? (
    <LoadingScreen />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        padding: "0 10px",
      }}
    >
      <Box
        sx={{
          width: {
            sm: "100%",
            lg: "60%",
            xl: "30%",
          },
          height: "100vh",
          p: 5,
        }}
      >
        <img
          src={logo}
          style={{
            fill: "red",
            color: "red",
          }}
          alt="logo"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color: "#212b36",
              }}
              gutterBottom
            >
              Login to SmartReplies.ai
            </Typography>

            <Typography color={"rgb(99, 115, 129)"}>
              Streamline your app's review responses with SmartReplies.ai -
              where AI crafts thoughtful, personalized replies to enhance user
              engagement and satisfaction effortlessly.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              py: 3,
              // padding: "30px",
              // height: "100%",
            }}
          >
            <CustomButton
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderColor: "#1a73e8",
                color: "#1a73e8",
                ":hover": {
                  color: "#1a73e8",
                },
              }}
              onClick={() => {
                window.location.href =
                  import.meta.env.VITE_BACKEND_URI + "auth/google";
              }}
              // color="inherit"
            >
              <Google />
              <Typography>Login with Google</Typography>
            </CustomButton>
          </Box>
          <Typography>
            Questions? Contact{" "}
            <a
              style={{
                color: "#1a73e8",
              }}
              href="mailto:info@smartreplies.ai"
            >
              info@smartreplies.ai
            </a>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <img
          style={{
            width: "100%",
            height: "98vh",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          src={background}
          alt="authentication background image"
          loading="lazy"
          // fetchPriority="high"
        />
      </Box>
    </Box>
  );
};

export default Login;
