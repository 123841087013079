import { Box } from "@mui/material";
import { LoaderCircle } from "lucide-react";

const LoadingScreen = ({ height }: { height?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: height ?? "100vh",
      }}
    >
      <LoaderCircle
        style={{
          width: 40,
          height: 40,
          color: "#1a73e8",
        }}
        className="loader"
      />
    </Box>
  );
};

export default LoadingScreen;
