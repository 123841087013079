import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { usegetUser, useLogout } from "@/api/hooks/use-user-reqs";
import { userState } from "@/store/user";
import { TUser } from "@/types";

const useUser = () => {
  const [user, setUser] = React.useState<TUser | undefined>(undefined);
  const [globalUser, setGlobalUser] = useRecoilState(userState);
  const { RegisterUser, RegisterUserRefetch, RegisterUserLoading, error } = usegetUser();
  const { mutate } = useLogout();

  // Effect to handle user fetching if not authenticated and initialized
  useEffect(() => {
    if (RegisterUserLoading || user || globalUser.isInitialized) return;

    // Handle error and logout
    if (error) {
      setGlobalUser({
        user: null,
        isAuthenticated: false,
        isInitialized: true,
      });
      mutate(); // Logout on error
    } 
    // If global user is not available, refetch the user
    else if (!globalUser.user) {
      RegisterUserRefetch();
    }
  }, [globalUser.isInitialized, error, RegisterUserLoading]);

  // Effect to update local state once we get RegisterUser
  useEffect(() => {
    if (RegisterUser) {
      setUser(RegisterUser);
      setGlobalUser({
        user: RegisterUser,
        isAuthenticated: true,
        isInitialized: true,
      });
    }
  }, [RegisterUser]);

  // Function to manually refresh the user data
  const refreshUser = () => {
    if (!RegisterUserLoading) {
      RegisterUserRefetch();
    }
  };

  return {
    user: globalUser.user,
    isAuthenticated: globalUser.isAuthenticated,
    isInitialized: globalUser.isInitialized,
    refreshUser,
  };
};

export default useUser;
