import useUser from "@hooks/useUser";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLogout } from "@/api/hooks/use-user-reqs";
import { Box, Divider, FormControl, Select, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PATHS from "@routes/paths";
import { useRecoilState } from "recoil";
import { selectedAppsState } from "@/store/selectedApps";
import { useDeveloperApps } from "@/api/hooks/use-user-apps-reqs";
import { Coins } from "lucide-react";

const Navbar = () => {
  const [apps, setApps] = useRecoilState(selectedAppsState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const user = useUser();
  const { data: appslist, isSuccess: appListFetchSuccess } = useDeveloperApps(
    user?.user?.developerId ?? ""
  );
  const { mutate: logout, isSuccess } = useLogout();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isSuccess) {
      navigate(PATHS.login);
      // window.location.reload();
    }
    if (appListFetchSuccess) {
      setApps((prev) => ({
        ...prev,
        isInitialized: true,
        selectedApps: appslist ?? [],
        currentApp: appslist
          ? appslist.find((app) => app.appId === user.user?.favApp) ?? appslist?.[0]
          : null,
      }));
    }
  }, [isSuccess, appListFetchSuccess]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate(PATHS.profile);
    handleClose();
  };

  return (
    <nav className="w-[100%] flex items-center justify-between md:pb-5 ">
      <div className="flex items-center justify-end w-full gap-3">
        <Link to={PATHS.subscriptions}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              color: "darkslategray",
            }}
          >
            <Coins />
            <Typography variant="h6" color="darkslategray">
              {user.user?.balance ?? 100}
            </Typography>
          </Box>
        </Link>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            mx: "10px",
          }}
        />
        <FormControl>
          <Select
            value={apps.currentApp?.appId ?? ""}
            sx={{ minWidth: "300px", height: "40px" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                },
              },
            }}
            onChange={(e) => {
              const selectedApp = apps.selectedApps.find(
                (app) => app.appId === e.target.value
              );
              setApps({
                currentApp: selectedApp ?? null,
                selectedApps: apps.selectedApps,
                isInitialized: true,
                appSettings: apps.appSettings,
              });
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {appslist?.map((app) => (
              <MenuItem key={app.appId} value={app.appId}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src={app.icon}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    alt={app.title}
                  />
                  {app.title}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              p: 0,
              minWidth: 0,
              borderRadius: "50%",
              border: "1px solid",
              borderColor: "text.white",
            }}
          >
            <img
              src={user.user?.profile_image}
              alt="profile image"
              className="rounded-full w-8 h-8"
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiMenu-paper": {
                borderRadius: "10px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                p: 1,
                minWidth: "150px",
              },
            }}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
