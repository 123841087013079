import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FiLogOut } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import { IoListCircleOutline, IoSettingsOutline } from "react-icons/io5";
import logo from "@/assets/logo-blue.png";
import logoIcon from "@/assets/logoMark/blue.svg";
import { Outlet, useNavigate } from "react-router-dom";
import PATHS from "@routes/paths";
import Navbar from "@components/Navbar";
import { useLogout } from "@/api/hooks/use-user-reqs";
import { Coins } from "lucide-react";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const [open, setOpen] = React.useState(
    window.innerWidth > 1024 ? true : false
  );
  const { mutate } = useLogout();
  const navigate = useNavigate();
  const path = window.location.pathname;

  const data = [
    {
      title: "Dashboard",
      path: PATHS.root,
      icon: <GoHome />,
    },
    {
      title: "Reviews",
      path: PATHS.reviews,
      icon: <IoListCircleOutline />,
    },
    {
      title: "Subscriptions",
      path: PATHS.subscriptions,
      icon: <Coins />,
    },{
      title: "Settings",
      path: PATHS.settings,
      icon: <IoSettingsOutline />,
    }
  ];

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleLogout = async () => {
    mutate();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} className="lg:static absolute">
        <DrawerHeader
          sx={{
            position: "absolute",
            zIndex: 50,
            left: open ? "25px" : "18px",
            bottom: "25px",
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={[
              {
                color: "black",
                bgcolor: "white",
                border: "1px solid #fff",
                borderRadius: "7px",
                p: "4px",
              },
              (theme) => ({
                "&:hover": {
                  bgcolor: "white",
                },
              }),
            ]}
          >
            {open ? (
              <ChevronRightIcon sx={{ fontSize: 20 }} />
            ) : (
              <ChevronLeftIcon sx={{ fontSize: 20 }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            background: "white",
            height: "100vh",
          }}
        >
          <Box sx={{ mx: 3, mt: 5, mb: 2, pr: open ? 4 : 0 }}>
            <img
              src={open ? logo : logoIcon}
              className="animate__animated animate__fadeIn"
              style={{
                fill: "#1a73e8",
                color: "#1a73e8",
                width: open ? "200px" : "40px",
              }}
            />
          </Box>
          <div
            className={`h-[1px] ${
              open ? "mx-6  bg-black/30" : "mx-4 mt-3  bg-[#3F3F46]"
            }`}
          />
          <List>
            {data?.map((val, index) => (
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={[
                    {
                      my: 1,
                      justifyContent: open ? "initial" : "center",
                      // px: 3,
                      mx: 2.5,
                      borderRadius: "10px",
                      color: path === val.path ? "#1a73e8" : "#3F3F46",
                      bgcolor:
                        path === val.path
                          ? open
                            ? "#E8F0FE"
                            : "white"
                          : "transparent",
                    },
                    (theme) => ({
                      "&:hover": {
                        bgcolor: "#E8F0FE",
                        color: "#1a73e8",
                        mx: 2.5,
                        borderRadius: "10px",
                      },
                    }),
                  ]}
                  className="sidebar-button-hover"
                  onClick={() => navigate(val.path)}
                >
                  {!open && (
                    <ListItemIcon
                      sx={[
                        {
                          mr: open ? 1.5 : "auto",
                          mx: 0,
                          justifyContent: "center",
                          p: 1.5,
                          fontSize: 20,
                          fontWeight: 700,
                          color: path !== val.path ? "#3F3F46" : "#1a73e8",
                          bgcolor:
                            path === val.path ? "#E8F0FE" : "transparent",
                          borderRadius: "10px",
                        },
                      ]}
                      className="list-icon"
                    >
                      {val?.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={val?.title}
                    sx={{ opacity: open ? 1 : 0 }}
                    className="list-item"
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <div
            className={`h-[1px] ${
              open ? "mx-6  bg-black/30" : "mx-4  bg-[#3F3F46]"
            }`}
          />
          <List>
       
            <ListItemButton
              sx={[
                {
                  my: 1,
                  justifyContent: open ? "initial" : "center",
                  px: 2,
                  mx: 2.5,
                  borderRadius: "10px",
                  color: path === "/logout" ? "#1a73e8" : "#3F3F46",
                  bgcolor:
                    path === "/logout"
                      ? open
                        ? "#E8F0FE"
                        : "white"
                      : "transparent",
                },
                (theme) => ({
                  "&:hover": {
                    bgcolor: "#E8F0FE",
                    color: "#1a73e8",
                    mx: 2.5,
                    borderRadius: "10px",
                  },
                }),
              ]}
              className="sidebar-button-hover"
              onClick={handleLogout}
            >
              {!open && (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.5 : "auto",
                    justifyContent: "center",
                    fontSize: 19,
                    fontWeight: 700,
                    color: path === "/logout" ? "#1a73e8" : "#3F3F46",
                    ":hover": {
                      color: "#1a73e8",
                    },
                  }}
                  className="list-icon"
                >
                  <FiLogOut />
                </ListItemIcon>
              )}
              <ListItemText
                primary="Logout"
                sx={{ opacity: open ? 1 : 0 }}
                className="list-item"
              />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        className="lg:ms-0 ms-20"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "#fff",
          height: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Navbar />
        <Outlet />
      </Box>
    </Box>
  );
}
