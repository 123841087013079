import LoadingScreen from "@components/LoadingScreen";
import useUser from "@hooks/useUser";
import Login from "@pages/auth/Login";
import React from "react";
import { useState, ReactNode } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

// ----------------------------------------------------------------------
type AuthGuardProps = {
  children?: ReactNode;
};

// ----------------------------------------------------------------------
export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, user, isInitialized } = useUser();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!isInitialized) {
    return <LoadingScreen />;
  }


  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children ? children : <Outlet />;
}
