import { Suspense, lazy, ElementType } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
//
import PATHS from "@routes/paths";
import LoadingScreen from "@components/LoadingScreen";

// ----------------------------------------------------------------------
import AuthGuard from "@/guards/AuthGuard";
import GuestGuard from "@/guards/GuestGuard";
import NotFound from "@pages/404";
import Sidebar from "@components/Sidebar";

// ----------------------------------------------------------------------
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import("@pages/auth/Login")));
const Dashboard = Loadable(lazy(() => import("@pages/Dashboard")));
const LandingPage = Loadable(lazy(() => import("@pages/LandingPage")));
const Pricing = Loadable(lazy(() => import("@pages/Pricing")));
const Validate = Loadable(lazy(() => import("@pages/auth/Validate")));
const SelectUserName = Loadable(
  lazy(() => import("@pages/Onboarding/SelectUsername"))
);
const Reviews = Loadable(lazy(() => import("@pages/Reviews")));
const Subscription = Loadable(lazy(() => import("@pages/Subscription")));
const Settings = Loadable(lazy(() => import("@pages/Settings")));
const Profile = Loadable(lazy(() => import("@pages/Profile")));
const Policy = Loadable(lazy(() => import("@pages/PrivacyPolicy")))
const Terms = Loadable(lazy(() => import("@pages/TermsAndConditions")))

// ----------------------------------------------------------------------
export default function Router() {
  return (
    <Routes>
      {/* safety exit route */}
      <Route path={PATHS.landingPage} element={<LandingPage />} />
      <Route path={PATHS.pricing} element={<Pricing />} />
      <Route path={PATHS.validate} element={<Validate />} />
      <Route path={PATHS.policy} element={<Policy />} />
      <Route path={PATHS.terms} element={<Terms />} />

      {/* PUBLIC routes */}
      <Route element={<GuestGuard />}>
        <Route path={PATHS.login} element={<Login />} />
        {/* <Route path={PATHS.logout} element={<Logout />} /> */}
      </Route>

      {/* AUTH routes */}
      <Route
        element={
          <AuthGuard>
            {/* <Navbar /> */}
            <Outlet />
          </AuthGuard>
        }
      >
        <Route path={PATHS.onboarding.username} element={<SelectUserName />} />

        <Route element={<Sidebar />}>
          <Route path={PATHS.root} element={<Dashboard />} />
          <Route path={PATHS.dashboard} element={<Dashboard />} />
          <Route path={PATHS.reviews} element={<Reviews />} />
          <Route path={PATHS.subscriptions} element={<Subscription />} />
          <Route path={PATHS.settings} element={<Settings />} />
          <Route path={PATHS.profile} element={<Profile />} />
        </Route>
      </Route>

      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
