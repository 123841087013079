export const API_ENDPOINTS = {
  // Auth
  USER: "/users",
  USER_ME: "/users/me",
  UPDATE_DEVELOPER_ID: "/users/developer",
  UPDATE_COMPANY: "/users/company",
  LOGOUT: "/auth/logout",


  // APPS
  GET_APPS: "/apps",
  GET_APP: (id: string) => "/apps/details/" + id,
  GET_USER_APPS: (id: string) => "/apps/user/" + id,
  GET_DEVELOPER_APPS: (devId: string) => "/apps/developer/" + devId,
  GET_APP_REVIEWS: (appId: string) => "/apps/reviews/" + appId,
  CREATE_APP: "/apps/add",
  GENERATE_REPLY: "/apps/review/generate-reply",
  REPLY_TO_REVIEW: "/apps/review/reply",
  TRANSLATE_TEXT: "/apps/translate",
} as const;
