import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, useLocation } from "react-router-dom";
import { RecoilRoot } from "recoil";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1a73e8",
    },
    text: {
      primary: "#212b36",
    },
  },

});

// don't use unless on dev stage / localhost
const DebugRouter = ({ children }: { children: any }) => {
  const location = useLocation();
  if (import.meta.env.NODE_ENV === "development") {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state
      )}`
    );
  }

  return children;
};

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <DebugRouter>
          <RecoilRoot>
            <App />
          </RecoilRoot>
        </DebugRouter>
      </BrowserRouter>
    </ThemeProvider>
  </StrictMode>
);
