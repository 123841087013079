import { atom } from "recoil";
import type { TUser } from "@/types";

type TUserState = {
    user: TUser | null;
    isAuthenticated: boolean;
    isInitialized: boolean;
};

export const userState = atom<TUserState >({
  key: "user",
  default: {
    user: null,
    isAuthenticated: false,
    isInitialized: false,
  },
});
