import { atom } from "recoil";
import type { TApps, TAppSettings } from "@/types";

type TSelectedAppsState = {
  selectedApps: TApps[];
  currentApp: TApps | null; 
  appSettings: TAppSettings | null;
  isInitialized: boolean; 
};

export const selectedAppsState = atom<TSelectedAppsState>({
  key: "selectedApps",
  default: {
    selectedApps: [],
    appSettings: null,
    currentApp: null,
    isInitialized: false,
  },
});

