import Axios, { AxiosRequestConfig } from "axios";
import { isEmpty } from "lodash";

import queryString from "query-string";

const API_URL = import.meta.env.VITE_BACKEND_URI;
export const axios = Axios.create({
  baseURL: API_URL,
  timeout: 5000000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axios.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    return Promise.reject(error);
  }
);
export class HttpClient {
  static async get(url: string, params: Record<string, unknown> = {}) {
    const query = queryString.stringify(params);
    const urlWithQuerryString = isEmpty(query) ? url : `${url}?${query}`;
    try {
      const response = await axios.get(urlWithQuerryString);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async post(
    url: string,
    data: any,
    options?: AxiosRequestConfig<any> | undefined
  ) {
    const response = await axios.post(url, data, options);
    return response.data;
  }

  static async put(
    url: string,
    data: any,
    options?: AxiosRequestConfig<any> | undefined
  ) {
    const response = await axios.put(url, data, options);
    return response.data;
  }
  static async patch(url: string, data: any) {
    const response = await axios.patch(url, data);
    return response.data;
  }

  static async delete(url: string) {
    const response = await axios.delete(url);
    return response.data;
  }
}

export function getFormErrors(error: { response: { data: { message: any } } }) {
  if (Axios.isAxiosError(error)) {
    return error.response?.data.message;
  }
  return null;
}

export function getFieldErrors(error: { response: { data: { errors: any } } }) {
  if (Axios.isAxiosError(error)) {
    return error.response?.data.errors;
  }
  return null;
}
