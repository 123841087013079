import { TAppDetail, TApps, TReview } from "@/types";
import { API_ENDPOINTS } from "../api-endpoints";
import { HttpClient } from "../http-client";

interface userName {
  username: string;
}
interface CompanyBody {
  companyName: string;
  position: string;
}

export const UserApps = {
  /**
   *
   * @param id
   * @returns
   */
  getUserApps: (id: string): Promise<TApps[]> => {
    return HttpClient.get(`${API_ENDPOINTS.GET_USER_APPS(id)}`);
  },

  translateText: (text: string, language: string): Promise<string> => {
    return HttpClient.post(`${API_ENDPOINTS.TRANSLATE_TEXT}`, { text, language });
  },

  /**
   *
   * @returns
   */
  getAppsList: (): Promise<TApps[]> => {
    return HttpClient.get(`${API_ENDPOINTS.GET_APPS}`);
  },

  /**
   *
   * @param id
   * @returns
   */
  getAppDetails: (id: string): Promise<TAppDetail> => {
    return HttpClient.get(`${API_ENDPOINTS.GET_APP(id)}`);
  },

  /**
   *
   * @param devId
   * @returns
   */
  getDeveloperApps: (devId: string): Promise<TApps[]> => {
    return HttpClient.get(`${API_ENDPOINTS.GET_DEVELOPER_APPS(devId)}`);
  },

  /**
   *
   * @param appId
   * @returns
   */
  getAppReviews: (appId: string): Promise<{ reviews: TReview[] }> => {
    return HttpClient.get(`${API_ENDPOINTS.GET_APP_REVIEWS(appId)}`);
  },

  /**
   *
   * @param body
   * @returns
   */
  addApp: (
    body: {
      appID: string;
      userId: string;
    }[]
  ): Promise<any> => {
    return HttpClient.post(`${API_ENDPOINTS.CREATE_APP}`, body);
  },

  /**
   *
   * @param body
   * @returns
   */
  generateReply: (body: {
    appId: string;
    reviewId: string;
  }): Promise<string> => {
    return HttpClient.post(`${API_ENDPOINTS.GENERATE_REPLY}`, body);
  },

  /**
   *
   * @param body
   * @returns
   */
  replyToReview: (body: {
    replyText: string;
    reviewId: string;
    appId: string;
  }): Promise<any> => {
    return HttpClient.post(`${API_ENDPOINTS.REPLY_TO_REVIEW}`, body);
  },
};
