import { API_ENDPOINTS } from "../api-endpoints";
import { HttpClient } from "../http-client";

interface developerId {
  developerId: string;
  favApp: string;
}
interface CompanyBody {
  companyName: string;
  position: string;
}

export const User = {
  /**
   *
   * @param id
   * @param body
   * @returns
   */
  setDeveloperId: (id: string, body: developerId): Promise<any> => {
    return HttpClient.put(`${API_ENDPOINTS.UPDATE_DEVELOPER_ID}/${id}`, body);
  },
  /**
   *
   * @param id
   * @param body
   * @returns
   */
  setCompanyName: (id: string, body: CompanyBody): Promise<any> => {
    return HttpClient.put(`${API_ENDPOINTS.UPDATE_COMPANY}/${id}`, {
      companyName: body.companyName,
      position: body.position
    });
  },
  /**
   *
   * @returns
   */
  getUser: async () => {
    try {
      const response = await HttpClient.get(API_ENDPOINTS.USER_ME);
      return response;
    } catch (error) {
      throw error;
    }
  },

  logout: async () => {
    try {
      const response = await HttpClient.get(API_ENDPOINTS.LOGOUT);
      return response;
    } catch (error) {
      throw error;
    }
  }
};
