import { useMutation, useQuery } from "@tanstack/react-query";
import { User } from "../client/http-reqs/user";
import { TUser } from "@/types";

interface UserBody {
  developerId: string; // Changed "string" to string
  favApp: string
  id: string;
}
interface CompanyBody {
  companyName: string;
  position: string;
  id: string;
}
export function usedeveloperId() {
  return useMutation<unknown, unknown, UserBody>({
    mutationFn: (body: UserBody) => User.setDeveloperId(body.id, {developerId: body.developerId, favApp: body.favApp}),
  });
}
export function useCompanyName() {
  return useMutation<unknown, unknown, CompanyBody>({
    mutationFn: (body: CompanyBody) => User.setCompanyName(body.id, body),
  });
}
export function usegetUser() {
  const { data, isLoading, error, status, isSuccess, refetch } = useQuery<TUser>({
    queryKey: ["useRegisterUser"],
    queryFn: async () => {
      return await User.getUser();
    },
    retry: 1,
    
  });

  return {
    RegisterUser: data,
    RegisterUserLoading: isLoading,
    error,
    status,
    isSuccess,
    RegisterUserRefetch: refetch,
  };
}

export function useLogout() {
  return useMutation<unknown, unknown>({
    mutationFn: () => User.logout(),
    onSuccess: () => {
      // if (window.location.pathname !== "/") {
        window.location.reload();
      // }
    },
  
  })
}