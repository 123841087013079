import { SnackbarProvider } from "notistack";
import ScrollToTop from "@components/ScrollToTop";
import MotionLazyContainer from "@components/animate/MotionLazyContainer";
import Router from "./routes";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();

  return (
    <MotionLazyContainer>
      <Toaster position="top-right" reverseOrder={false} />
      <SnackbarProvider />
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </MotionLazyContainer>
  );
}

export default App;
