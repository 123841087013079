import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(Button)({
  // your custom styles go here
  p: 50,
  borderRadius: 8,
  height: 50,
  width: "100%",
}) as typeof Button;
